<template>
<v-card class="mx-auto" outlined v-on:click="detailChart">
    <v-card-text class="pt-2">
        <div class="mb-2 brown--text">{{dataInfo.title}}</div>
        <v-divider class="my-2"></v-divider>
        <div class="pt-5 text-center">
            <span v-if="fontSize === 'small'" class="text-h5 font-weight-black">{{dataInfo.data}}</span>
            <span v-else class="text-h4 font-weight-black">{{dataInfo.data}}</span>
            <v-icon v-if="dataInfo.range > 0" small color="red" class="top-minus">mdi-arrow-up-thin</v-icon>
            <v-icon v-if="dataInfo.range == 0" small color="black" class="top-minus">mdi-minus</v-icon>
            <v-icon v-if="dataInfo.range < 0" small color="blue" class="top-minus">mdi-arrow-down-thin</v-icon>
            <span v-if="dataInfo.range > 0" class="font-weight-black red--text">{{dataInfo.range}}</span>
            <span v-if="dataInfo.range == 0" class="font-weight-black black--text">{{dataInfo.range}}</span>
            <span v-if="dataInfo.range < 0" class="font-weight-black blue--text">{{dataInfo.range}}</span>
        </div>
        <div v-if="dataInfo.useRateUnit != null" class="text-center">
            YoY
            <v-icon v-if="dataInfo.yoy > 0" small color="red" class="top-minus">mdi-arrow-up-thin</v-icon>
            <v-icon v-if="dataInfo.yoy == 0" small color="black" class="top-minus">mdi-minus</v-icon>
            <v-icon v-if="dataInfo.yoy < 0" small color="blue" class="top-minus">mdi-arrow-down-thin</v-icon>
            <span v-if="dataInfo.yoy > 0" class="font-weight-black red--text">{{dataInfo.yoy}}%</span>
            <span v-if="dataInfo.yoy == 0" class="font-weight-black black--text">{{dataInfo.yoy}}%</span>
            <span v-if="dataInfo.yoy < 0" class="font-weight-black blue--text">{{dataInfo.yoy}}%</span>
            &nbsp;
            {{dataInfo.useRateUnit}}
            <v-icon v-if="dataInfo.prev > 0" small color="red" class="top-minus">mdi-arrow-up-thin</v-icon>
            <v-icon v-if="dataInfo.prev == 0" small color="black" class="top-minus">mdi-minus</v-icon>
            <v-icon v-if="dataInfo.prev < 0" small color="blue" class="top-minus">mdi-arrow-down-thin</v-icon>
            <span v-if="dataInfo.prev > 0" class="font-weight-black red--text">{{dataInfo.prev}}%</span>
            <span v-if="dataInfo.prev == 0" class="font-weight-black black--text">{{dataInfo.prev}}%</span>
            <span v-if="dataInfo.prev < 0" class="font-weight-black blue--text">{{dataInfo.prev}}%</span>
        </div>
        <div v-else>&nbsp;</div>
        <v-sparkline class="v-v" :value="dataInfo.sparklineList" color="rgba(100,40,40,0.5)" line-width="4" auto-draw></v-sparkline>
        <v-icon class="mr-2" small> mdi-clock </v-icon>
        <span class="text-caption font-weight-black">{{dataInfo.footData}}</span>
    </v-card-text>
    <v-dialog persistent v-model="dialog" max-width="80%">
        <DetailChart1 :dataInfo="dataInfo" v-on:detailChartClose="detailChartClose"/>
    </v-dialog>
</v-card>
</template>

<script>
import DetailChart1 from './DetailChart1.vue';


export default {
    components: {
        DetailChart1
    },
    props: {
        dataInfo: {
            type: Object,
        },
        fontSize: {
            type: String,
        }
    },
    data() {
        return {
            dialog: false
        }
    },
    methods: {
        detailChart: function(){
            this.dialog = true;
        },
        detailChartClose: function(){            
            this.dialog = false;
        }
    }
};
</script>

<style>

.v-v {
    top: -20px;
    position: relative;
}

.top-minus {
    top: -1px;
}

.v-card--link:focus {
  background: white;
}

.v-card--link:focus::before {
  opacity: 0;
}
</style>
