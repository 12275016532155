<template>
  <v-navigation-drawer app width="250" class="brown lighten-5" v-model="localDrawer">
    <div class="mt-3">
      <v-img src="@/assets/buycycle.svg" width="250" />
    </div>
    <v-list dense nav class="mt-5 ml-5">
      <v-list-group v-for="item in items" :key="item.title" v-model="item.active" :prepend-icon="item.action" no-action>
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title v-text="item.title"></v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item v-for="child in item.items" :key="child.title" :href="child.path">
          <v-list-item-title v-text="child.title"></v-list-item-title>
        </v-list-item>
      </v-list-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  props: {
    title: String,
    drawer: Boolean
  },

  data() {
    return {
      localDrawer: this.drawer,
      inFlag: false,
      outFlag: false,
      items: [
        {
          action: "mdi-view-dashboard",
          active: true,
          items: [
            { title: "경제지표", path: "/" },
            { title: "채권", path: "/bond" },
            { title: "환율 (원)", path: "/exchangeRateWon" },
            { title: "환율 (달러)", path: "/exchangeRateDollar" }
          ],
          title: "상황판",
        },
      ],
    }
  },

  methods: {
    setDrawer: function (drawer) {
      if (!this.outFlag) {
        this.inFlag = true;
        this.localDrawer = drawer;
      }
    }
  },

  watch: {
    localDrawer: function () {
      if (!this.inFlag) {
        this.outFlag = true;
        this.$emit('changeDrawer', this.localDrawer);
        this.outFlag = false;
      }else{
        this.inFlag = false;
      }
    }
  }
};
</script>
