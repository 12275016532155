<template>
  <v-app id="App">
    <AppBar title="상환판 - 경제" v-bind:drawer="drawer" v-on:changeDrawer="changeDrawer" ref="bar" />
    <Navigation v-bind:drawer="drawer" v-on:changeDrawer="changeDrawer" ref="navi" />
    <v-main class="brown lighten-5">
      <template>
        <v-container fluid>
          <v-row>
            <v-col cols="6" md="3">
              <SparklinesType1 :dataInfo="brDataInfo" />
            </v-col>
            <v-col cols="6" md="3">
              <SparklinesType1 :dataInfo="eiDataInfo" />
            </v-col>
            <v-col cols="6" md="3">
              <SparklinesType1 :dataInfo="ppiDataInfo" />
            </v-col>
            <v-col cols="6" md="3">
              <SparklinesType1 :dataInfo="cpiDataInfo" />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <SparklinesType1 :dataInfo="ccliDataInfo" />
            </v-col>
            <v-col cols="6">
              <SparklinesType1 :dataInfo="ccciDataInfo" />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="4">
              <SparklinesType1 :dataInfo="gdpDataInfo" :fontSize="'small'" />
            </v-col>
            <v-col cols="12" md="4">
              <SparklinesType1 :dataInfo="gdiDataInfo" :fontSize="'small'" />
            </v-col>
            <v-col cols="12" md="4">
              <SparklinesType1 :dataInfo="gniDataInfo" :fontSize="'small'" />
            </v-col>
          </v-row>
        </v-container>
      </template>
    </v-main>
    <v-footer app> </v-footer>
  </v-app>
</template>

<script>
import AppBar from "../CommonAppBar.vue";
import Navigation from "../CommonNavigation.vue";
import SparklinesType1 from "../../components/SparklinesType1";
import axios from "axios";

export default {
  name: "App",

  components: {
    AppBar,
    Navigation,
    SparklinesType1,
  },

  data() {
    return {
      drawer: true,
      brDataInfo: {},
      eiDataInfo: {},
      // neDataInfo: {},
      // outsDataInfo: {},
      ppiDataInfo: {},
      cpiDataInfo: {},
      ccliDataInfo: {},
      ccciDataInfo: {},
      gdpDataInfo: {},
      gdiDataInfo: {},
      gniDataInfo: {},
    };
  },

  created() {
    this.loadDashboardMainData();
  },

  methods: {
    loadDashboardMainData: function () {
      axios
        .get("/api/dashboard/main")
        .then((response) => {
          if (response && response.data !== null) {
            this.brDataInfo = response.data.resultData.br;
            this.eiDataInfo = response.data.resultData.ei;
            // this.neDataInfo = response.data.resultData.ne;
            // this.outsDataInfo = response.data.resultData.outs;
            this.ppiDataInfo = response.data.resultData.ppi;
            this.cpiDataInfo = response.data.resultData.cpi;
            this.ccliDataInfo = response.data.resultData.ccli;
            this.ccciDataInfo = response.data.resultData.ccci;
            this.gdpDataInfo = response.data.resultData.gdp;
            this.gdiDataInfo = response.data.resultData.gdi;
            this.gniDataInfo = response.data.resultData.gni;
          }
        })
        .catch((error) => {
          console.log("error : ", error);
        });
    },
    changeDrawer: function (childDrawer) {
      this.$refs.bar.setDrawer(childDrawer);
      this.$refs.navi.setDrawer(childDrawer);
    },
  },
};
</script>

<style>
.v-v {
  top: -20px;
  position: relative;
}

.top-minus {
  top: -1px;
}
</style>
