<template>
  <v-app-bar app elevate-on-scroll elevation="1" class="brown lighten-5" dense>
    <v-app-bar-nav-icon @click.stop="changeDrawer"></v-app-bar-nav-icon>
    <v-toolbar-title>{{ title }}</v-toolbar-title>
    <v-spacer></v-spacer>
    <v-btn icon>
      <v-icon>mdi-magnify</v-icon>
    </v-btn>
  </v-app-bar>
</template>

<script>
export default {
  props: {
    title: String,
    drawer: Boolean,
  },

  data() {
    return {
      localDrawer: this.drawer,
    };
  },

  methods: {
    changeDrawer: function () {
      this.$emit("changeDrawer", !this.localDrawer);
    },
    setDrawer: function (drawer) {
      this.localDrawer = drawer;
    }
  }
};
</script>
