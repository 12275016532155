<template>
  <v-card>
    <v-system-bar
        color="#ddcccc"
      >
      <v-spacer></v-spacer>
      <v-btn icon v-on:click="thisClose">
        <v-icon color="#000000">mdi-close</v-icon>
      </v-btn>
      </v-system-bar>
      <v-card-title class="mb-5">
        {{dataInfo.title}}
      </v-card-title>
      <v-card-subtitle>
        <v-row>
          <v-col cols="7">검색 가능 기간 : {{ ss }} ~ {{ se }}</v-col>
          <v-col cols="2">
            <v-text-field
              dense
              label="검색 시작일"
              v-model="startDate"
            ></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-text-field
              dense
              label="검색 종료일"
              v-model="endDate"
            ></v-text-field>
          </v-col>
          <v-col cols="1">
            <v-btn icon v-on:click="requestData">
              <v-icon color="#666666">mdi-database-search-outline</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-subtitle>
    <LineChartGenerator
      :chart-data="chartData"
      :chart-id="chartId"
      :dataset-id-key="datasetIdKey"
      :plugins="plugins"
      :css-classes="cssClasses"
      :styles="styles"
      :width="width"
      :height="height"
    />
  </v-card>
  
</template>

<script>
import { Line as LineChartGenerator } from 'vue-chartjs'
import axios from "axios";

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement
} from 'chart.js'

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement
)

export default {

  components: {
    LineChartGenerator
  },
  props: {
    dataInfo: {
      type: Object,
    },
    chartId: {
      type: String,
      default: 'line-chart'
    },
    datasetIdKey: {
      type: String,
      default: 'label'
    },
    width: {
      type: Number,
      default: 400
    },
    height: {
      type: Number,
      default: 400
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      default: () => {}
    },
    plugins: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return{
      chartData: {},
      startDate: null,
      endDate: null,
      ss: null,
      se: null,
    }
  },
  created() {
    this.searchDateSetUp();
    this.datePeriodData();
    this.requestData();
  },
  
  methods: {
    searchDateSetUp: function(){
      if(this.startDate == null){
          this.startDate = (this.dataInfo.footData.split("~"))[0];
          this.startDate = this.startDate.replace(".", "").replace("Q", "").replace("M", "").replace("D", "").replace(" ","");
      }
      if(this.endDate == null){
          this.endDate = (this.dataInfo.footData.split("~"))[1];
          this.endDate = this.endDate.replace(".", "").replace("Q", "").replace("M", "").replace("D", "").replace(" ","");
      }
    },
    datePeriodData: function(){
      axios
      .get("/api/chart/datePeriodData/" + this.dataInfo.id)
      .then((response) => {
          if (response && response.data !== null && response.data.resultCode == 200) {
              this.ss = response.data.startDate;
              this.se = response.data.endDate;
          }
      })
      .catch((error) => {
          console.log("error : ", error);
      });
    },
    requestData: function(){
      axios
      .get("/api/chart/" + this.dataInfo.id+ "/"+this.startDate+"/"+this.endDate)
      .then((response) => {
          if (response && response.data !== null && response.data.resultCode == 200) {
              this.chartData = response.data.chartData;
          }
      })
      .catch((error) => {
          console.log("error : ", error);
      });
    },
    thisClose: function(){            
      this.$emit("detailChartClose");
    },
    reloadRequestData: function(item){
      axios
      .get("/api/chart/" + item.id+ "/"+this.startDate+"/"+this.endDate)
      .then((response) => {
          if (response && response.data !== null && response.data.resultCode == 200) {
              this.chartData = response.data.chartData;
          }
      })
      .catch((error) => {
          console.log("error : ", error);
      });
    }
  }
};
</script>